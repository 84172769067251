/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Quicksand:wght@500&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap@4.6.0/dist/css/bootstrap.min.css");
* {
  padding: 0;
  margin: 0;
  font-family: "Quicksand", sans-serif;
}
body {
  background: #fff;
  padding: 90px 0 0;
}
.container {
  margin: 0 auto;
}
.banner-image {
  background: #ffffff63 url("https://images.pexels.com/photos/5486892/pexels-photo-5486892.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260") no-repeat center center;
  background-size: cover;
  padding: 60px 0;
//   background-attachment: fixed;
  .overlay{
      position: absolute;
      background: rgba(255, 255, 255, 0.7);
      width:100%;
      height:100%;
      left:0;
      top:0;
  }
}
ul.navigation {
  position: fixed;
  top: 50%;
  left: 50%;
  background: #fff;
  height: 100%;
  width: 100%;
  transform: translate(-50%, -50%);
}
ul.navigation li {
  list-style: none;
  position: relative;
  text-align: center;
  overflow: hidden;
}
ul.navigation li::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -100%;
  transform: translateY(-50%);
  width: 100%;
  height: 3px;
  background: #262626;
  transition: 0.5s;
}
ul.navigation li:hover::before {
  left: 100%;
}
ul.navigation li a {
  position: relative;
  display: inline-block;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 50px;
  padding: 20px 40px;
}
ul.navigation li a::before,
ul.navigation li a::after {
  content: attr(data);
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px 40px;
  color: #262626;
  transition: 0.9s;
  transition-delay: 0.3s;
}
ul.navigation li a::before {
  clip-path: polygon(0 0, 100% 0, 100% 50%, 0 50%);
}
ul.navigation li a::after {
  clip-path: polygon(0 50%, 100% 50%, 100% 100%, 0 100%);
}
ul.navigation li:hover a::before {
  top: -3px;
  left: 7px;
  color: #ff5722;
}
ul.navigation li:hover a::after {
  top: 3px;
  left: -7px;
  color: #ff5722;
}

#menu-close {
    position: absolute;
    top: 15px;
    right: 45px;
    font-size: 36px;
}
.col-title-wrapper {
  position: relative;
  margin: 15px 0px 30px;
  .col-title {
    color: #272727;
    font-weight: bold;
    margin-bottom: 30px;
  }
  .subtitle {
    color: #ff8d00;
    font-weight: normal;
    font-size: 16px;
  }
  .bgtitle {
    position: absolute;
    top: -70px;
    color: rgba(0, 0, 0, 0.1);
    font-weight: bold;
    font-size: 120px;
  }
  &.other-section {
    .col-title {
      color: #272727;
      font-weight: bold;
      font-size: 2rem;
    }
    .subtitle {
      color: #ff8d00;
      font-weight: normal;
      font-size: 14px;
    }
    .bgtitle {
      position: absolute;
      top: -40px;
      color: rgba(0, 0, 0, 0.1);
      font-weight: bold;
      font-size: 70px;
    }
  }
}
a {
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 14px;
  transition: all 0.5s ease-in-out;
}
.btn-gold {
  border: 2px solid #ff8d00;
  border-radius: 20px;
  padding: 7px 30px;
  color: #ff8d00;
  margin-top: 30px;
  transition: all 0.5s ease-in-out;
  &:hover {
    background: #ff8d00;
    color: #fff;
  }
}

ul {
  margin-top: 15px;
  list-style: none;
  li {
    list-style: none;
  }
}
section {
  padding: 70px 0;

  &.no-pad {
    padding: 0;
  }

  .col-md-4,
  .com-md-12,
  .col-md-6,
  .col-md-8,
  .col-md-12 {
    padding: 15px;
  }

  .content {
    margin-top: 15px;
    padding: 15px 0;
  }
}
.bg-brand {
  background-color: rgba(255, 141, 0,.1);
}
footer.bg-brand-dark  {
.col-title {
    color: rgb(255, 141, 0) !important;
}
}
.bg-brand-dark {
    background-color: rgb(3, 3, 3);

    *{
        color: #fff;

    }
    h1{
        color: #777!important;

    }
}
.brand-wrapper {
  padding: 30px;
  min-height: 600px;
  .image-wrapper-right {
    text-align: right;
  }
  .image-wrapper-center {
    text-align: center;
  }
  img {
    height: 200px;
    width: auto;
  }
}

.sale-badge {
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  background: #ff8d00;
  color: #fff;
}

.pad-15 {
  padding: 15px;
}

.pad-30 {
  padding: 30px;
}

.counts span {
  font-size: 72px;
}

#gallery {
  padding-top: 40px;
  @media screen and (min-width: 991px) {
    padding: 60px 30px 0 30px;
  }
}

.img-wrapper {
  position: relative;
  margin-top: 15px;
  img {
    width: 100%;
  }
}
.img-overlay {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  i {
    color: #fff;
    font-size: 3em;
  }
}

#overlay {
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  // Removes blue highlight
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  img {
    margin: 0;
    width: 80%;
    height: auto;
    object-fit: contain;
    padding: 5%;
    @media screen and (min-width: 768px) {
      width: 60%;
    }
    @media screen and (min-width: 1200px) {
      width: 50%;
    }
  }
}

#nextButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;
  &:hover {
    opacity: 0.7;
  }
  @media screen and (min-width: 768px) {
    font-size: 3em;
  }
}

#prevButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;
  &:hover {
    opacity: 0.7;
  }
  @media screen and (min-width: 768px) {
    font-size: 3em;
  }
}

#exitButton {
  color: #fff;
  font-size: 2em;
  transition: opacity 0.8s;
  position: absolute;
  top: 15px;
  right: 15px;
  &:hover {
    opacity: 0.7;
  }
  @media screen and (min-width: 768px) {
    font-size: 3em;
  }
}

// contact form


/*---------------------------------------------*/

input {
    outline: none;
    border: none;
  }
  
  textarea {
  outline: none;
  border: none; 
  }
  
  textarea:focus, input:focus {
    border-color: transparent !important;
  }
  
  input::-webkit-input-placeholder { color: #bdbdd3;}
  input:-moz-placeholder { color: #bdbdd3;}
  input::-moz-placeholder { color: #bdbdd3;}
  input:-ms-input-placeholder { color: #bdbdd3;}
  
  textarea::-webkit-input-placeholder { color: #bdbdd3;}
  textarea:-moz-placeholder { color: #bdbdd3;}
  textarea::-moz-placeholder { color: #bdbdd3;}
  textarea:-ms-input-placeholder { color: #bdbdd3;}
  
  
  /*==================================================================
  [ Contact - Area]*/
  
  .container-contact {
    width: 100%;  
    min-height: 70vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding: 15px;
    position: relative;
    background-color: transparent;
  }
  
  .wrap-contact {
    width: 700px;
    background: transparent;
    border: 1px solid #b3b3b3;
    padding: 40px 0px 20px 0px;
  }
  
  .wrap-contact-image {
    width: 600px;
    background: transparent;
    padding: 40px 0px 20px 0px;
  }
  
  /*==================================================================
  [ Contact - Form ]*/
  
  .contact-form {
    width: 100%;
  }
  
  .error {
    padding: 5px 9px;
    border: 1px solid red;
    color: red;
    border-radius: 3px;
  }
   
  .success {
    padding: 5px 9px;
    border: 1px solid green;
    color: green;
    border-radius: 3px;
  }
  
  
  /*------------------------------------------------------------------
  [ Input ]*/
  
  .wrap-input {
    width: 90%;
    background-color: #fff;
    border: 1px solid #b3b3b3;
    border-radius: 7px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    position: relative;
    z-index: 1;
  }
  
  
  .input {
    position: relative;
    display: block;
    width: 100%;
    background: #fff;
    border-radius: 31px;
    color: #8f8fa1;
    line-height: 1.2;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 0.2em;
    height: 42px;
    padding-left: 10px;
    padding-right: 10px;
    text-transform: none;
  }
  
  /*---------------------------------------------*/
  
  input.input {
    height: 62px;
    padding: 0 15px 0 15px;
  }
  
  
  textarea.input {
    min-height: 169px;
    padding: 19px 15px 0 15px;
  }
  
  /*------------------------------------------------------------------
  [ Focus Input ]*/
  
  .focus-input {
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 31px;
    background-color: #fff;
    pointer-events: none;
    
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
  
  .input:focus + .focus-input100 {
    width: calc(100% + 20px);
  }
  
  /*------------------------------------------------------------------
  [ Button ]*/
  .container-contact-form-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 10px;
  }
  
  .contact-form-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    min-width: 250px;
    height: 50px;
    background-color: transparent;
    border-radius:7px;
    cursor: pointer;
  
    font-size: 16px;
    color: #fff;
    line-height: 1.2;
    text-transform: uppercase;
  
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
    position: relative;
    z-index: 1;
  }
  
  .contact-form-btn::before {
    content: "";
    display: block;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    border-radius: 7px;
    background-color: #9e8c7b;
    pointer-events: none;
    
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  }
  
  .contact-form-btn:hover:before {
    background-color: #916439;
  }
  
  .validate-input {
    position: relative;
  }
  
  /*==================================================================
  [ Info Grid]*/
  
  .grid-container {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 100px;
    grid-gap: 100px;
    padding: 10px 30px 0px 30px;
  }
  
  .grid-container > div {
    padding: 20px 0;
    font-size: 10px;
  }
  
  .left-align {
      text-align: left;
  }
  
  .right-align {
      text-align: right;
  }
  
  
  /*==================================================================
  [  Hero title ]*/
  
  
  .listing-hero {
      background: #F7F0EA;
      padding: 101px 0 30px;
  }
  
  .hero-heading {
      margin: 0 auto;
      width: 100%;
      max-width: 709px;
  }
  
  section#post-28 {
      padding-top: 155px;
  }
  
  .hero-large {
      display: inline-block;
      width: 40%;
      font-weight: 300;
      font-size: 56px;
      line-height: 56px;
      position: relative;
      top: -19px;
      color: #454545;
  }
  
  .hero-text {
      display: inline-block;
      width: 59%;
      vertical-align: top;
      border-left: 1px solid #8E8E8E;
      padding: 0 33px;
      font-size: 15px;
      line-height: 23px;
      /* or 153% */
      color: #454545;
      vertical-align: top;
  }
  
  
  .hero-text .btn.no-border {
      margin-top: 15px;
      margin-bottom: 6.5px;
      background-repeat: no-repeat;
      background-size: 25px;
      display: block;
      background-position: right center;
      max-width: 99px;
  }
  
  .hero-text i {
      font-style: normal;
      margin-top: -6px;
      display: block;
  }
  a{
    color: #ff8d00;
    text-decoration: none;
    &:hover{
        text-decoration: none;
    }
    margin: 7px;
}
  footer{
      padding: 60px 0 ;
      text-align: center;
      .content-footer{
          color: #333;
      }
  }

  header.main-nav{
      box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
      background: #fff;
      
      *{
      color: #ff8d00;
      }
    position: fixed;
    height: 90px;
    width: 100%;
    padding: 15px;
    top:0;
    left:0;
    z-index: 999;

    .col-md-9 {
        line-height: 45px;
        *{
            font-size: 1rem;
            text-transform: uppercase;
            font-weight: bold;
            &:hover{
                color: #333;
                letter-spacing: 2px;
            }
        }
    }

  }
@media only screen and (max-width: 767px) {
  .col-title-wrapper .bgtitle {
    position: absolute;
    top: -44px;
    color: rgba(0, 0, 0, 0.1);
    font-weight: bold;
    font-size: 84px;
}
.col-xs-6 {
    width: 50% !important;
}
}